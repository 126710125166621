import React from 'react'

const StatusTable = () => {
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                <div className="inline-block min-w-full py-1 sm:px-0 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full">
                            <thead className="border-b">
                                <tr className="w-full">
                                    <th scope="col" className="columns-12"></th>
                                    <th scope="col" className="columns px-6 py-2 text-center text-sm font-medium">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-b">
                                    <td className="whitespace-nowrap text-[0.75rem] py-2 font-semibold"><span className="border-x-[4px] rounded-[90px] border-x-[#34B5FE] me-1"></span>My Data</td>
                                    <td className="whitespace-nowrap border-l text-center font-bold text-[0.75rem]">5</td>
                                </tr>
                                <tr className="border-b">
                                    <td className="whitespace-nowrap text-[0.75rem] font-semibold py-2"><span className="border-x-[4px] rounded-[90px] border-x-[#FF9300] me-1"></span>My Peers - Region</td>
                                    <td className="whitespace-nowrap border-l py-2 text-sm font-medium text-center">10</td>
                                </tr>
                                <tr className="border-b">
                                    <td className="whitespace-nowrap text-[0.75rem] font-semibold"><span className="border-x-[4px] rounded-[90px] border-x-[#7CC84A] me-1"></span> Peers - Platform</td>
                                    <td className="whitespace-nowrap border-l text-center py-2 text-sm font-medium">13</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusTable