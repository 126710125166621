import React from 'react'
import backArr from "./../../assets/img/encounterAssets/Backward arrow.svg";
import info from "../../assets/img/encounterAssets/Group 829.svg";
import profile from "../../assets/img/encounterAssets/Group 3574.svg";
import switchIcon from "../../assets/img/encounterAssets/Group 3154.svg";
import { useNavigate } from 'react-router-dom';

export default function EncounterTitleProfile(props) {
    const navigate = useNavigate();

    return (
        <div className='justify-between px-4'>
            <div className="flex justify-between pt-12">
                <div className="backArrow" onClick={()=>navigate(-1) }><img src={backArr} alt="backarrow" /></div>
                <div className="title text-white font-display-regular text-base"><h2>{props.head}</h2></div>
                <div className="info"><img src={info} alt="infoIcon" /></div>
            </div>

            <div className='align-middle pt-2'>
                <div class="flex justify-between">
                    <div class="col flex">
                        <div className='en-data flex items-center gap-2'>
                            <img src={profile} className='en-pro-img' alt='pro-img' />
                            <p className='flex text-sm font-display-regular text-[#8DC63F]'>My Data</p>
                        </div>
                    </div>
                    <div class="col flex justify-end">
                        <div className='flex items-center -me-4'>
                            <p className='en-switch-name flex align-middle text-sm font-display-regular text-white'>Switch to NA/PA</p>
                            <img src={switchIcon} className='en-pro-img' alt='pro-img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}