import React from 'react'

const GoogleRating = () => {
  return (
    <div>
      Google Rating
    </div>
  )
}

export default GoogleRating
