import React from 'react';
import circle from "../../../assets/img/npBookingWindowAssets/circle.svg";
import info from "../../../assets/img/npBookingWindowAssets/info.svg";
import GaugeChart from 'react-gauge-chart';
import circleB from "../../../assets/img/npBookingWindowAssets/circle-black.svg";
import infoB from "../../../assets/img/npBookingWindowAssets/info-black.svg";
import arrowNext from "../../../assets/img/npBookingWindowAssets/forwardArrow.svg";
import calendar from "../../../assets/img/npBookingWindowAssets/calendar.svg";

const OpenKPI = () => {

  const chartStyle = {
    width: 80
  }

  return (
    <div className='h-auto overflow-scroll absolute w-full top-[13rem]'>
      <div className='bg-white flex flex-col px-4 fixed w-full top-[10rem] pb-3 cursor-pointer z-20'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-3'>
            <div>
              <img src={calendar} alt="calendar" />
            </div>
            <div className='flex flex-col'>
              <p className='font-display-medium text-base'>Last 90 Days</p>
              <p className='font-display-medium text-[#989898] text-base'>Apr 1 - Jun 30 '23</p>
            </div>
          </div>
          <div>
            <img src={arrowNext} alt="next" />
          </div>
        </div>
        <hr className='mt-1' />
      </div>
      <div className=' bg-white  p-4 z-10' >
        <div className='border-[#F7943C] border mt-4 rounded-[13px] bg-[#F5F5F5]'>
          <div className='flex justify-between bg-[#F7943C] rounded-[9px] py-1 px-2 items-center'>
            <div>
              <img src={circle} alt="circle" className='absolute' />
              <p className='font-display-regular text-[0.625rem] relative ms-3 text-white'>Jun 30<sup>th</sup></p>
            </div>
            <p className='font-display-regular text-sm text-white'>NP Booking Window</p>
            <img src={info} alt="info" />
          </div>
          <div className='flex justify-between p-3'>
            <div >
              <GaugeChart id="gauge-chart1" percent={0.5} animate={false} nrOfLevels={3} colors={["#27AE61", "#F7943C", "#DF1616"]} arcWidth={0.5} hideText={true} style={chartStyle} arcPadding={0} cornerRadius={0} />
            </div>

            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
              <p className='font-display-medium text-[#F7943C] text-base'>7 Days</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Region</p>
              <p className='font-display-medium text-lite-black text-base'>8.2</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Platform</p>
              <p className='font-display-medium text-lite-black text-base'>9</p>
            </div>
            <div>
            </div>
          </div>
        </div>

        <div className='border-[#27AE61] border mt-4 rounded-[13px] bg-[#F5F5F5]'>
          <div className='flex justify-between bg-[#27AE61] rounded-[9px] py-1 px-2 items-center'>
            <div>
              <img src={circle} alt="circle" className='absolute' />
              <p className='font-display-regular text-[0.625rem] relative ms-3 text-white'>Jun 30<sup>th</sup></p>
            </div>
            <p className='font-display-regular text-sm text-white'>No Show Rate YoY</p>
            <img src={info} alt="info" />
          </div>
          <div className='flex justify-between p-3'>
            <div>
              <GaugeChart id="gauge-chart1" percent={0.5} animate={false} nrOfLevels={3} colors={["#27AE61", "#F7943C", "#DF1616"]} arcWidth={0.5} hideText={true} style={chartStyle} arcPadding={0} cornerRadius={0} />
            </div>

            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
              <p className='font-display-medium text-[#27AE61] text-base'>9%</p>
            </div>

            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Region </p>
              <p className='font-display-medium text-lite-black text-base'>18%</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Platform </p>
              <p className='font-display-medium text-lite-black text-base'>11%</p>
            </div>
            <div>
            </div>
          </div>
        </div>

        <div className='border-[#27AE61] border mt-4 rounded-[13px] bg-[#F5F5F5]'>
          <div className='flex justify-between bg-[#27AE61] rounded-[7px] py-1 px-2 items-center'>
            <div>
              <img src={circle} alt="circle" className='absolute' />
              <p className='font-display-regular text-[0.625rem] relative ms-3 text-white'>Jun 30<sup>th</sup></p>
            </div>
            <p className='font-display-regular text-sm text-white font-thin'>Surgeries Without Prior Auth</p>
            <img src={info} alt="info" />
          </div>
          <div className='p-3'>
            <div className='flex justify-between pt-3'>

              <GaugeChart id="gauge-chart1" percent={0.2} animate={false} nrOfLevels={3} colors={["#27AE61", "#F7943C", "#DF1616"]} arcWidth={0.5} hideText={true} style={chartStyle} arcPadding={0} cornerRadius={0} />

              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
                <p className='font-display-medium text-[#27AE61] text-base'>5%</p>
              </div>
              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
                <p className='font-display-medium text-lite-black text-base'>21%</p>
              </div>
              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
                <p className='font-display-medium text-lite-black text-base'>16%</p>
              </div>
              <div>
              </div>
            </div>

            <hr className='border border-[#98989866] mt-3 mx-3' />

            <div className='mt-3 flex justify-around'>
              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>Total Amount</p>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>'22 vs '23</p>
                <p className='font-display-medium text-[#27AE61] text-base'>9%</p>
              </div>
              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>Total Amount</p>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>2022</p>
                <p className='font-display-medium text-lite-black text-base'>$43,174</p>
              </div>
              <div className='flex flex-col items-center'>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>Total Amount</p>
                <p className='font-display-regular text-lite-black text-[0.75rem]'>'22 vs '23</p>
                <p className='font-display-medium text-[#27AE61] text-base'>$4289</p>
              </div>
            </div>
          </div>
        </div>

        <div className='border-[#D6D6D6] border mt-4 rounded-[13px] bg-[#F5F5F5]'>
          <div className='flex justify-between bg-[#D6D6D6] rounded-[9px] py-1 px-2 items-center'>
            <div>
              <img src={circleB} alt="circle" className='absolute' />
              <p className='font-display-regular text-[0.625rem] relative ms-3 text-lite-black'>Jun 30<sup>th</sup></p>
            </div>
            <p className='font-display-medium text-sm text-lite-black'>Scheduling Next Appt</p>
            <img src={infoB} alt="info" />
          </div>
          <div className='flex justify-around p-3'>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
              <p className='font-display-medium text-lite-black text-base'>2 Days</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Region</p>
              <p className='font-display-medium text-lite-black text-base'>6 Days</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Platform</p>
              <p className='font-display-medium text-lite-black text-base'>4 Days</p>
            </div>
          </div>
        </div>

        <div className='border-[#D6D6D6] border mt-4 rounded-[13px] bg-[#F5F5F5]'>
          <div className='flex justify-between bg-[#D6D6D6] rounded-[9px] py-1 px-2'>
            <div>
              <img src={circleB} alt="circle" className='absolute' />
              <p className='font-display-regular text-[0.625rem] relative ms-3 text-lite-black'>Jun 30<sup>th</sup></p>
            </div>
            <p className='font-display-medium text-sm text-lite-black'>Percentage Paid by Patients</p>
            <img src={infoB} alt="info" />
          </div>
          <div className='flex justify-around p-3'>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>My Data </p>
              <p className='font-display-medium text-lite-black text-base'>75%</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Region</p>
              <p className='font-display-medium text-lite-black text-base'>60%</p>
            </div>
            <div className='flex flex-col items-center'>
              <p className='font-display-regular text-lite-black text-[0.75rem]'>Platform</p>
              <p className='font-display-medium text-lite-black text-base'>57%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default OpenKPI
