import React, { useState } from 'react';
import tickMark from "../../../../assets/img/clinicalAssets/tickMark.svg";
import tickMarkActive from "../../../../assets/img/clinicalAssets/tickMarkActive.svg";
// import TableData from '../../AllComponents/TableData';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import DashFooter from '../../DashFooter';

const NPS = () => {
  const [npsRegion, setNpsRegion] = useState(false);

  const data = [
    {
      name: 'Apr',
      MyData: -50,
      NpsPlatform: -45,
      NpsRegion: -60
    },
    {
      name: 'May',
      MyData: -25,
      NpsPlatform: -60,
      NpsRegion: -80
    },
    {
      name: 'Jun',
      MyData: 0,
      NpsPlatform: -70,
      NpsRegion: -75
    },
    {
      name: 'Jul',
      MyData: 25,
      NpsPlatform: -48,
      NpsRegion: -75
    },
    {
      name: 'Aug',
      MyData: 20,
      NpsPlatform: -23,
      NpsRegion: -69
    },
    {
      name: 'Sep',
      MyData: 15,
      NpsPlatform: -10,
      NpsRegion: -27
    },
    {
      name: 'Oct',
      MyData: 2,
      NpsPlatform: 2,
      NpsRegion: -10
    },
    {
      name: 'Nov',
      MyData: 20,
      NpsPlatform: 48,
      NpsRegion: 2
    },
    {
      name: 'Dec',
      MyData: 34,
      NpsPlatform: 52,
      NpsRegion: 25
    },
    {
      name: 'Jan',
      MyData: 50,
      NpsPlatform: 59,
      NpsRegion: 49
    },
    {
      name: 'Feb',
      MyData: 68,
      NpsPlatform: 48,
      NpsRegion: 38
    },
    {
      name: 'Mar',
      MyData: 75,
      NpsPlatform: 32,
      NpsRegion: 25
    },
  ];

  return (
    <div>
      <div className='bg-white fixed w-full top-[9.9rem] z-10'>
        <div className='flex justify-center'>
          <p className='font-display-regular text-lite-black text-[0.75rem] text-center tracking-wide py-3'>Data from Apr '22 to Mar '23 - Comparison <br /> with Region and Platform medians</p>
        </div>

        <div style={{ height: "200px", width: '100%', paddingRight: '10px' }}>
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              margin={{
                top: 5,
                right: 5,
                bottom: 0,
                left: -20
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#34B5FECC" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#34B5FECC" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis dataKey="name" tick={{ fontSize: 9 }} tickMargin={7} />
              <YAxis tick={{ fontSize: 9 }} tickMargin={5} domain={['auto', 'auto']} allowDataOverflow={true} />
              <Tooltip />
              <Legend />
              <Area dataKey="MyData" stroke="#34B5FECC" fillOpacity={1} fill="#34B5FECC" />
              <Line dataKey="NpsPlatform" stroke="#FF9300" dot={false} />
              {/* <Line dataKey="NpsPlatform" stroke="#ff7300" dot={false}/> */}
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <div className='flex justify-center mt-2 gap-1'>
          {npsRegion ? <img src={tickMarkActive} alt="tickMarkActive" onClick={() => setNpsRegion(false)} /> : <img src={tickMark} alt="tickMark" onClick={() => setNpsRegion(true)} />
          }
          <p className='text-[#989899] font-display-regular text-[0.75rem]'>Show region benchmark in the graph</p>
        </div>

        <div className='flex justify-between font-display-regular text-lite-black text-[0.625rem] px-8 pt-4'>
          <p>Month</p>
          <div className='flex flex-col items-center'>
            <div className='flex items-center gap-1'>
              <div className='w-2 h-2 bg-[#34B5FE] rounded-full'>
              </div>
              <p>NPS</p>
            </div>
            <p>My Data</p>
          </div>
          <div className='flex flex-col items-center'>
            <div className='flex items-center gap-1'>
              <div className='h-[2px] w-4 bg-[#FF9300]'>
              </div>
              <p>NPS</p>
            </div>
            <p className='ms-2'>Platform</p>
          </div>
        </div>
      </div>

      <div className='bg-white h-auto z-10 absolute w-full top-[31rem] px-4 pb-4 font-display-regular text-[0.75rem] mt-2 overflow-scroll'>
        {/* <TableData />
        <TableData />
        <TableData />
        <TableData />
        <TableData avg="true" /> */}
      </div>
      <DashFooter />
    </div>

  )
}

export default NPS
