import { Routes, Route } from "react-router-dom";
import OpenNote from "./components/Dashboard/Clinical/OpenNote"
import EncounterMetrics from "./components/Dashboard/Clinical/EncounterMetrics";
import Home from './components/Splash';
import Dashboard from './components/Dashboard/Dashboard';
import NpBookingWindow from './components/Dashboard/Operations/NpBookingWindow';
import Referral from "./components/Dashboard/Clinical/Referral";
import ClaimReworks from "./components/Dashboard/Operations/ClaimReworks";
import PayerMix from "./components/Dashboard/Operations/PayerMix";
import MipsDash from "./components/Dashboard/Clinical/MipsDash";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/open-notes' element={<OpenNote />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/refer' element={<Referral />} />
        <Route path='/encounter-metrics' element={<EncounterMetrics />} />
        <Route path='/np-booking-window' element={<NpBookingWindow />} />
        <Route path='/claim-reworks' element={<ClaimReworks />} />
        <Route path='/payer-mix' element={<PayerMix />} />
        <Route path='/refer' element={<Referral />} />
        <Route path='/mips' element={<MipsDash />} />
      </Routes>
    </div>
  );
}

export default App