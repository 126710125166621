import React from 'react'
import EncounterTitleProfile from '../../AllComponents/EncounterTitleProfile'
import EMGrids from "../../AllComponents/Grid";
import location from "../../../assets/img/encounterAssets/location.svg";
import rightblue from "../../../assets/img/encounterAssets/greenRightArr.svg";
import intersection from "../../../assets/img/encounterAssets/intersection.svg";
import greenUpArr from "../../../assets/img/encounterAssets/greenUp.svg";
import updownArr from "../../../assets/img/encounterAssets/greenUpDownArr.svg";
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import fifty from "../../../assets/img/encounterAssets/50th.svg";
import graph from "../../../assets/img/encounterAssets/blueGraph.svg";
import StatusTable from '../../AllComponents/StatusTable';
import SyncDate from '../../AllComponents/SyncDate';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const EncounterMetrics = () => {
    const data = [
        {
            name: 'NP',
            uv: 39795,
            pv: 1895,
        },
        {
            name: 'FU',
            uv: 11151,
            pv: 531,
        },
        {
            name: 'Surg',
            uv: 4478,
            pv: 213,
        },
    ];

    const CustomizedLabel = (props) => {
        const { x, y, value } = props;

        return (
            // <svg xmlns="http://www.w3.org/2000/svg"   transform={`translate(${x},${y})`}>
            //     <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill="#c7eaff" />
            // </svg>
            <text x={x} y={y} dy={-7} fill="#34B5FE" fontSize={10} textAnchor="start" >
                {value}
            </text>
        );
    }

    return (
        <div className='bg-[#1B453E] bg-cover w-full z-20'>
            <EncounterTitleProfile head="Encounter Metrics" />

            {/*Encounter metrics sub head content started */}
            <div className="bg-white rounded-t-[26px] mx-auto z-10 overflow-scroll">

                <div className="px-4 mt-2 mx-auto head">

                    {/*two grids started here */}
                    <div>
                        <div className="grid grid-cols-2 gap-3 round">
                            <EMGrids icon={location} title="Silver Spring" subTitle="400 E Wayne Ave, MD 20901, US" classname="info-card1 h-[6.62rem] rounded-[12px] px-3 mt-2" icolor="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center my-2" rightI={rightblue} subcolor="text-white text-[0.625rem] mt-1 font-display-regular" />
                            <EMGrids icon={intersection} title="90 Days" subTitle="Mar 1'23 - Jun 1'23" classname="info-card2 h-[6.62rem] rounded-[12px] px-3 mt-2" icolor="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center my-2" rightI={greenUpArr} subcolor="text-[#096531] text-white text-[0.625rem] mt-1 font-display-regular" />
                        </div>
                    </div>

                    {/*comparison regional started */}
                    <div>
                        <div className="mt-5">
                            <div className="flex flex-wrap">
                                <div className="w-2/3 reg-1 text-start mx-1 flex gap-2">
                                    <p className='text-xs tracking-tighter font-semibold md:font-bold'>Comparison with Regional Median</p>
                                    <input type="image" img src={updownArr} className='h-3.5 mt-4' alt='reg-arr' />
                                </div>

                                <div className="w-1/4 ms-auto flex reg-opp justify-end gap-2">
                                    <input type="image" img src={fifty} className='w-auto h-auto md:w-12' alt="fifty" />
                                    <input type="image" img src={graph} alt="" className="w-auto h-auto md:w-12" />
                                </div>

                                <div className="reg-date flex mx-1 gap-1 -mt-2 md:-mt-4">
                                    <SyncDate date="Jun 30" superScript="th" icon={greenRing} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Status table */}
                    <div className='h-64 mt-6'>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 20,
                                    left: -10,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis dataKey="name" tick={{ fontSize: 10 }} tickMargin={7} />
                                <YAxis tick={{ fontSize: 10 }} type="number" domain={['dataMin', "auto"]} allowDataOverflow={true} tickMargin={5} />
                                <Tooltip />
                                <Bar dataKey="pv" fill="#34B5FE" barSize={20} label={<CustomizedLabel />} />
                                <Bar dataKey="uv" fill="#FF9300" barSize={20} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div>
                        <StatusTable />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EncounterMetrics