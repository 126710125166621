import React from 'react';
import EncounterTitle from "../../AllComponents/EncounterTitleProfile";
import SyncDate from '../../AllComponents/SyncDate';
import StatusTable from '../../AllComponents/StatusTable';
import StatusBar from '../../AllComponents/StatusBar';
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import greenUpArr from "../../../assets/img/encounterAssets/greenUp.svg";
import location from "../../../assets/img/encounterAssets/location.svg";
import rightGreen from "../../../assets/img/encounterAssets/greenRightArr.svg";
import Subgrids from "../../AllComponents/Grid";
import DashFooter from '../DashFooter';

const OpenNote = () => {
    return (
        <div className="bg-[#1B453E] bg-cover h-screen fixed w-full z-20">

            <div>
                <EncounterTitle head="Open Notes" />
            </div>

            {/*Open note sub head content started */}
            <div className="bg-white h-screen rounded-t-[26px] mx-auto content-baseline z-10 overflow-scroll">

                <div className='px-4'>
                    <div className="grid grid-cols-2 gap-3">
                        <Subgrids icon={location} title="All Center" subTitle="Cumberland & 6 others" classname="info-card1 h-[6.62rem] rounded-[12px] px-4 mt-3" icolor="rounded bg-[#096531] h-8 w-8 flex justify-center items-center my-2" rightI={rightGreen} subcolor="text-white text-[0.62rem] mt-1" />
                        <Subgrids icon={greenRing} title="DTD Comparison" subTitle="Today" classname="info-card2 h-[6.62rem] rounded-[12px] px-4 mt-3" icolor="rounded bg-[#4E8700] h-8 w-8 flex justify-center items-center my-2" rightI={greenUpArr} subcolor="text-white text-[0.62rem] mt-1" />
                    </div>

                    <div className="mt-4" >
                        <SyncDate date="May 22" superScript="nd" />
                    </div>

                    {/*status bar starts */}
                    <div className='h-64 mt-4'>
                        <StatusBar />
                    </div>

                    {/* table section*/}
                    <div>
                        <StatusTable />
                    </div>
                </div>
            </div>
            <div>
                <DashFooter />
            </div>
        </div>
    )
}

export default OpenNote; 
