import React, { useState } from 'react';
import back from "../../../assets/img/npBookingWindowAssets/backArrow.svg";
import { useNavigate } from 'react-router-dom';
import NPS from './MIPS/NPS';
import MIPS from './MIPS/MIPS';
import GoogleRating from './MIPS/GoogleRating';
import DashFooter from '../DashFooter';

const MipsDash = () => {
  const [nps, setNps] = useState(false);
  const [googleRating, setGoogleRating] = useState(true);
  const [mips, setMips] = useState(false);

  const navigate = useNavigate();
  return (
    <div>
      <div className='bg-[#1B453E] fixed w-full top-0 z-20'>
        <div className="flex justify-between px-4 pb-4 pt-14">
          <div className="cursor-pointer" onClick={() => navigate('/dashboard')}><img src={back} alt="back" /></div>
          <div className="text-[#FFFFFF] text-base font-display-medium">Comparative Analytics</div>
          <div></div>
        </div>

        <div className="bg-white rounded-t-[26px] px-4 ">
          <div className="grid grid-cols-3 gap-3 py-4">
            <button className={`rounded-[12px] font-display-regular py-2 ${nps ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setNps(true); setGoogleRating(false); setMips(false) }}>NPS</button>
            <button className={`rounded-[12px] font-display-regular py-2 ${googleRating ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setGoogleRating(true); setNps(false); setMips(false) }}>Google Rating</button>
            <button className={`rounded-[12px] font-display-regular py-2 ${mips ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setMips(true); setGoogleRating(false); setNps(false) }}>MIPS</button>
          </div>
        </div>
      </div>
      {nps ? <NPS /> : ''}
      {googleRating ? <GoogleRating /> : ''}
      {mips ? <MIPS /> : ''}

      <div className='mt-16'>
        <DashFooter />
      </div>

    </div>
  )
}

export default MipsDash
