import React from 'react';
import refresh from "../../../../assets/img/clinicalAssets/circleGrey.svg";
import info from "../../../../assets/img/clinicalAssets/infoGrey.svg";
import greenUpDownArr from "../../../../assets/img/clinicalAssets/greenUpDownArr.svg";
import { BarChart, Bar, Cell, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

const MIPS = () => {

  const colors = ['#34B5FE', '#FF9300', '#7CC84A'];

  const data = [
    {
      name: 'My-Data',
      uv: 84,
    },
    {
      name: 'My Peers-Region',
      uv: 100,
    },
    {
      name: 'My Peers-Platform',
      uv: 56,
    },
  ];

  const CustomizedLabel = (props) => {
    const { x, y, value, fill } = props;

    return (
      <text x={x} y={y} dy={-7} dx={19} fill={fill} fontSize={24} fontFamily='SF-Pro-Display-Bold' textAnchor="center" >
        {value}
      </text>
    );
  }

  function CustomizedTick(props) {
    const { x, y, payload } = props;
    // const tspans = payload.value.split("");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} fill="#666" fontSize={12} fontFamily='SF-Pro-Display-Medium' textAnchor="middle">
          {payload.value}
          {/* {payload.value} */}
          {/* {tspans.map((value)=> (
            <tspan textAnchor="middle" x="0" >{value}</tspan>
          ))} */}
          {/* <tspan textAnchor="middle" x="0">            
          </tspan>
          <tspan textAnchor="middle" x="0" dy="10">
            {payload.value}
          </tspan>          */}
        </text>
      </g>
    );
  }

  return (
    <div className=''>
      <div className='bg-white absolute w-full top-[9.6rem] px-4 pt-4 h-auto overflow-scroll z-10'>
        <div className='flex justify-between bg-[#1B453E26] rounded-xl py-2 px-3'>
          <div className='flex gap-2 items-center justify-center'>
            <p className='text-[#1B453E] font-display-medium'>MIPS</p>
            <img src={refresh} alt="refresh" />
            <p className='text-[#989899] font-display-regular text-[0.7rem]'>Jun 30, Updated Monthly</p>
          </div>
          <img src={greenUpDownArr} alt="dropdown" />
        </div>

        <div className='flex justify-center'>
          <p className='font-display-regular text-lite-black text-[0.75rem] text-center tracking-wide py-3'>Data from Jun '22 to Mar '23 - Comparison <br /> with Region and Platform medians</p>
        </div>

        {/* Bar Chart */}
        <div className='h-72 mt-4'>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              dataKey="value"
              margin={{
                top: 30,
                right: 0,
                left: -29,
                bottom: 0,
              }}
              barCategoryGap={5}
            >
              <CartesianGrid vertical={false} />
              <XAxis axisLine={false} dataKey="name" tickLine={false} tick={<CustomizedTick />} tickMargin={7} fontFamily='SF-Pro-Display-Medium' />
              <YAxis axisLine={false}   tickLine={false} tick={{ fontSize: 14 }} type="number" domain={[0, "auto"]} tickMargin={5} />
              {/* <Tooltip /> */}
              <Bar dataKey="uv" label={<CustomizedLabel />} >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]} radius={[5, 5, 0, 0]} />
                ))}
              </Bar>
              {/* <Bar dataKey="a" fill="#34B5FE"  radius={[6, 6, 0, 0]} label={<CustomizedLabel />}/>
              <Bar dataKey="b" fill="#FF9300"  radius={[6, 6, 0, 0]} label={<CustomizedLabel />}/>
              <Bar dataKey="c" fill="#7CC84A"  radius={[6, 6, 0, 0]} label={<CustomizedLabel />}/> */}
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className='flex mt-7 justify-evenly'>
          <div className='text-[#989899] font-display-regular text-[0.8rem]'>Regional Percentile:<span className='font-display-medium text-lite-black text-sm ms-1'>50<sup>th</sup></span></div>
          <div className='text-[#989899] font-display-regular text-[0.8rem]'>Platform Percentile:<span className='font-display-medium text-lite-black text-sm ms-1'>50<sup>th</sup></span></div>
        </div>

        <div className='mt-4 bg-[#F5F5F5] p-4 rounded-lg mb-20'>
          <div className='flex justify-between'>
            <div></div>
            <div className='font-display-regular text-sm'>Estimated Final Score</div>
            <div><img src={info} alt="info" /></div>
          </div>
          <div className='flex justify-evenly mt-3'>
            <div className='bg-[#D20F25] flex rounded-lg flex-col justify-center items-center px-9 py-2 '>
              <p className='text-white text-[0.7rem] font-display-regular text-center'>Estimated Minimum</p>
              <p className='font-display-medium text-base text-white text-center'>56</p>
            </div>

            <div className='bg-[#139429] flex rounded-lg flex-col justify-center items-center px-9 py-2'>
              <p className='text-white text-[0.7rem] font-display-regular text-center'>Estimated Minimum</p>
              <p className='font-display-medium text-base text-white text-center'>100</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MIPS
