import React from "react";
import SyncDate from "../../AllComponents/SyncDate";
import arrowNext from "../../../assets/img/npBookingWindowAssets/forwardArrow.svg";
import calendar from "../../../assets/img/npBookingWindowAssets/calendar.svg";
import ring from "../../../assets/img/npBookingWindowAssets/greenRing.svg";
import next from "../../../assets/img/npBookingWindowAssets/next.svg";
import nextGrey from "../../../assets/img/npBookingWindowAssets/nextGrey.svg";
import redArrow from "../../../assets/img/npBookingWindowAssets/redArrow.svg";
import greenArrow from "../../../assets/img/npBookingWindowAssets/greenArrow.svg";
import { useNavigate } from "react-router-dom";

const Summary = () => {
  const navigate = useNavigate();

  return (
    <div className="h-auto absolute w-full top-[15rem]">
      <div className="bg-white flex flex-col px-4 fixed w-full top-[10rem] pb-3 cursor-pointer z-20">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div>
              <img src={calendar} alt="calendar" />
            </div>
            <div className="flex flex-col">
              <p className="font-display-medium text-base">YTD</p>
              <p className="font-display-medium text-[#989898] text-base">
                Jan 1 - Jun 30 '23
              </p>
            </div>
          </div>
          <div>
            <img src={arrowNext} alt="next" />
          </div>
        </div>
        <hr className="mt-1" />
        <div className="mt-3">
          <SyncDate date="Jun 15" superScript="th" icon={ring} />
        </div>
      </div>
      <div className="h-auto px-4 pb-4 z-10" >
        <div className="mt-4 rounded-[13px] bg-gradient-to-r from-[#C4E5FE] to-[#98CFFB] p-3" onClick={() => navigate('/encounter-metrics')}>
          <div className="flex items-center justify-between">
            <p className="text-[#0082CC] text-sm font-display-medium">My Encounters</p>
            <img src={next} alt="next" className="mix-blend-overlay h-5 w-5" />
          </div>
          <div className="flex ms-48 py-2">
            <div className="gap-6 flex">
              <p className="text-[0.625rem] text-lite-black font-display-regular">My Data</p>
              <p className="text-[0.625rem] text-lite-black font-display-regular">Region</p>
              <p className="text-[0.625rem] text-lite-black font-display-regular">Platform</p>
            </div>
          </div>
          <hr className="bg-[#0082CC] h-[0.1px] border-none opacity-10 m-1" />
          <div className="flex justify-between m-1 font-display-regular text-lite-black text-[0.75rem] ">
            <p>New Patients</p>
            <div className="flex gap-12">
              <p>10%</p>
              <p>40%</p>
              <p>30%</p>
            </div>
          </div>
          <hr className="bg-[#0082CC] h-[0.1px] border-none opacity-10 m-1" />
          <div className="flex justify-between m-1 text-[0.75rem] text-lite-black font-display-regular">
            <p>Follow Ups</p>
            <div className="flex gap-12">
              <p>30%</p>
              <p>20%</p>
              <p>50%</p>
            </div>
          </div>
          <hr className="bg-[#0082CC] h-[0.1px] border-none opacity-10 m-1" />
          <div className="flex justify-between m-1 text-[0.75rem] text-lite-black font-display-regular">
            <p>Surgeries</p>
            <div className="flex gap-12">
              <p>60%</p>
              <p>40%</p>
              <p>20%</p>
            </div>
          </div>
        </div>

        <div className="flex mt-4 gap-3 w-full">
          <div className="rounded-[13px] bg-gradient-to-b from-[#C2FC73] to-[#8DC63F] w-[35%] p-3" onClick={() => navigate("/claim-reworks")}>
            <div className="flex justify-between items-center">
              <p className="text-[#096531] font-display-medium text-sm">Claim Reworks</p>
              <img
                src={next}
                alt="next"
                className="mix-blend-overlay h-5 w-5"
              />
            </div>
            <div className="flex flex-col bg-white rounded-[13px] p-3 mt-3 gap-2">
              <div className="flex flex-col text-center">
                <p className="text-[#18B854] font-display-bold text-base">30%</p>
                <p className="text-[0.62rem] text-lite-black font-display-regular">My Data</p>
              </div>
              <hr />
              <div className="flex flex-col text-center">
                <p className="text-[#18B854] font-display-bold text-base">48%</p>
                <p className="text-[0.62rem] text-lite-black font-display-regular">
                  My Peers Platform
                </p>
              </div>
            </div>
          </div>

          <div className="rounded-[13px] bg-gradient-to-b from-[#FBCCAB] to-[#FCE2B7] p-3 w-[65%]" onClick={() => navigate('/payer-mix')}>
            <div className="flex justify-between items-center">
              <p className="text-[#B97200] text-sm font-display-medium">Payer Mix</p>
              <img
                src={next}
                alt="next"
                className="mix-blend-overlay h-5 w-5"
              />
            </div>
            <div className="flex flex-col bg-white rounded-[13px] gap-2 mt-3 p-2">
              <div className="flex justify-between">
                <p className="text-[0.75rem] text-lite-black font-display-regular">Medicare</p>
                <p className="text-[0.75rem] text-[#B97200]">34.4%</p>
              </div>
              <div className="flex justify-between">
                <p className="text-[0.75rem] text-lite-black font-display-regular">UHC</p>
                <p className="text-[0.75rem] text-[#B97200]">13.9%</p>
              </div>
              <div className="flex justify-between">
                <p className="text-[0.75rem] text-lite-black font-display-regular">BCBS</p>
                <p className="text-[0.75rem] text-[#B97200]">10.8%</p>
              </div>
              <div className="flex justify-between">
                <p className="text-[0.75rem] text-lite-black font-display-regular">Humuna</p>
                <p className="text-[0.75rem] text-[#B97200]">8.5%</p>
              </div>
              <div className="flex justify-between">
                <p className="text-[0.75rem] text-lite-black font-display-regular">Wellcare</p>
                <p className="text-[0.75rem] text-[#B97200]">7.4%</p>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-4" />

        <div
          className="mt-4 bg-gradient-to-r from-[#DDDCFF] to-[#A9A9FB] rounded-[13px]"
          onClick={() => navigate("/open-notes")}
        >
          <div className="flex p-3 items-center">
            <div className="flex gap-4 w-[35%]">
              <p className="text-[#8300D8] text-sm font-display-medium">
                Open Notes
              </p>
              <img
                src={next}
                alt="next"
                className="mix-blend-overlay h-5 w-5"
              />
            </div>
            <div className="flex bg-white rounded-[13px] p-3 items-center justify-center w-[65%]">
              <div className="flex flex-col items-center justify-center border-r pe-5">
                <p className="text-base text-[#8300D8] font-display-bold">
                  40
                </p>
                <p className="text-[0.75rem] text-lite-black font-display-regular">My Data</p>
              </div>

              <div className="flex flex-col items-center justify-center ms-4">
                <p className="text-base text-[#8300D8] font-display-bold">
                  60
                </p>
                <p className="text-[0.75rem] text-lite-black font-display-regular">
                  My Peers Platform
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 bg-[#EFEDED] rounded-[13px] p-2">
          <div className="flex justify-between px-3 gap-2">
            <div>
              <div className="flex flex-col">
                <p className="text-lite-black text-sm font-display-medium ms-3">NPS</p>
                <div className="flex flex-col rounded-[13px] bg-white p-2 my-2 gap-2">
                  <div className="flex justify-center gap-1">
                    <img src={greenArrow} alt="greenArr" />
                    <p className="text-base text-[#18B854] font-display-medium">80.6%</p>
                  </div>
                  <div className="flex gap-3">
                    <p>80</p>
                    <p className="text-[0.75rem] text-[#888888]">Current Year</p>
                  </div>
                  <div className="flex gap-3">
                    <p>-2</p>
                    <p className="text-[0.75rem] text-[#888888]">Previous Year</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex-col">
                <p className="text-lite-black text-sm font-display-medium ms-3">Google Rating</p>
                <div className="flex flex-col bg-white rounded-[13px] p-2 my-2 gap-2">
                  <div className="flex justify-center gap-1">
                    <img src={redArrow} alt="reArr" />
                    <p className="text-base text-[#DF1616] font-display-medium">3.1%</p>
                  </div>
                  <div className="flex gap-3">
                    <p>4.2</p>
                    <p className="text-[0.75rem] text-[#888888]">Current Year</p>
                  </div>
                  <div className="flex gap-3">
                    <p>4.3</p>
                    <p className="text-[0.75rem] text-[#888888]">Previous Year</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex-col">
                <div className="flex justify-between">
                  <p className="text-lite-black text-sm font-display-medium ms-3">MIPS</p>
                  <img
                    src={nextGrey}
                    alt="next"
                    className="h-5 w-5 "
                  />
                </div>
                <div className="flex flex-col bg-white rounded-[13px] p-2 my-2 gap-2">
                  <div className="flex gap-3">
                    <p>88</p>
                    <p className="text-[0.75rem] text-[#888888]">Current Score</p>
                  </div>
                  <div className="flex gap-3">
                    <p>00<sup>th</sup></p>
                    <p className="text-[0.75rem] text-[#888888] flex-end">Platform Percentile</p>
                  </div>
                  <div className="flex gap-3">
                    <p>00<sup>th</sup></p>
                    <p className="text-[0.75rem] text-[#888888]">Regional Percentile</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
