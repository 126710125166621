import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function StatusBar() {

    const colors = ['#34B5FE', '#FF9300', '#7CC84A'];

    const data = [
        {
            name: 'My Data',
            uv: 5,
        },
        {
            name: 'My Peers - Region',
            uv: 10,
        },
        {
            name: 'My Peers - platform',
            uv: 13,
        },                                 
    ];

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                dataKey="value"
                margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeLinearray="" />
                <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                <YAxis tick={{ fontSize: 11 }} />
                <Tooltip />
                <Bar dataKey="uv"  fill="#8884d8" barSize={20} label={{ position: 'top' }}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                </Bar>                
            </BarChart>
        </ResponsiveContainer>
    )
}