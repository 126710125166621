import React from 'react';
import backArrow from "../../../assets/img/referralAssets/Backward arrow.svg";
import SyncDate from '../../AllComponents/SyncDate';
import Subgrids from "../../AllComponents/Grid";
import location from "../../../assets/img/encounterAssets/location.svg";
import rightblue from "../../../assets/img/encounterAssets/greenRightArr.svg";
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import greenUpArr from "../../../assets/img/encounterAssets/greenUp.svg";
import infoIcon from "../../../assets/img/referralAssets/infoIcon.svg";
// import blueBg from "../../../assets/img/referralAssets/blueBack.svg";
import fav from "../../../assets/img/referralAssets/fav.svg";
import next from "../../../assets/img/referralAssets/next.svg";
import DashFooter from '../DashFooter';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';

const colors = ['#9ADAFF', '#34B5FE'];

const data = [
  {
    name: 'My Data',
    uv: 52000,
  },
  {
    name: 'My Peers - Region',
    uv: 69000,
  },

];

const CustomizedLabel = (props) => {
  const { x, y, value, fill } = props;

  return (
    <text x={x} y={y} dy={-7} fill={fill} fontSize={10} textAnchor="start" >
      {value}
    </text>
  );
}

const Referral = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#1B453E]">
      <div className="flex justify-between pt-14 px-4 pb-4">
        <div className="backArrow" onClick={() => navigate('/dashboard')}><img src={backArrow} alt="backarrow" /></div>
        <div className="text-white text-base font-display-medium">Referral Sources</div>
        <div></div>
      </div>

      <div className="bg-white rounded-t-[26px] mx-auto content-baseline">
        <div className='px-4'>

          <div className="grid grid-cols-2 gap-3 pt-4">
            <button className='bg-[#8DC63F] rounded-[12px] font-display-regular text-white py-2'>Summary</button>
            <button className='bg-[#EFEDED] rounded-[12px] font-display-regular text-lite-black'>Trends</button>
            <Subgrids icon={location} title="Silver Spring" subTitle="400 E Wayne Ave, MD 20901, US" classname="info-card1 h-[6.62rem] rounded-[12px] px-3 mt-2" icolor="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center my-2" rightI={rightblue} subcolor="text-white text-[0.625rem] mt-1 font-display-regular" />
            <Subgrids icon={greenRing} title="90 Days" subTitle="Mar 1'23 - Jun 1'23" classname="info-card2 h-[6.62rem] rounded-[12px] px-3 mt-2" icolor="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center my-2" rightI={greenUpArr} subcolor="text-[#096531] text-white text-[0.625rem] mt-1 font-display-regular" />
          </div>

          <div className="mt-4" >
            <SyncDate date="Jun 30" superScript="th" />
          </div>

          <div className='bg-gradient-to-r from-[#f1f4f7] to-[#e8f5ff] rounded-[12px] mt-4 p-3'>
            <div className='flex justify-between'>
              <p className='font-display-bold text-lite-black'>Center Status</p>
              <img src={infoIcon} alt="info" />             
            </div>

            <div className='flex items-end'>
              <div className='w-[60%]'>
                <div className='flex flex-col gap-3 mt-3'>
                  <div className='bg-[#34B5FE] rounded-lg p-3'>
                    <p className='text-white font-display-bold'>69,532</p>
                    <p className='text-white text-[0.75rem] font-display-regular'>Current Period</p>
                  </div>
                  <div className='bg-[#9ADAFF] rounded-lg p-3'>
                    <p className='text-[#0082CC] font-display-bold'>+16,820</p>
                    <p className='text-lite-black text-[0.75rem] font-display-regular'>vs Previous Period</p>
                  </div>
                </div>
              </div>
              <div className='w-[40%] ps-4'>
                <div className='h-32'>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={data}
                      dataKey="value"
                      margin={{
                        top: 0,
                        right: 0,
                        left: 10,
                        bottom: 0,
                      }}
                    >                     
                      <Bar dataKey="uv" barSize={23} label={<CustomizedLabel />}>
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-4 bg-gradient-to-b from-[#FBCCAB] to-[#FCE2B7] rounded-[12px] p-3 '>
            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                <img src={fav} alt="fav" className='absolute' />
                <p className='ms-5 font-display-bold text-lite-black text-sm'>Top Favorites</p>
              </div>
              <img src={next} alt="next" className='mix-blend-overlay h-5 w-5' />
            </div>
            <div className='bg-white rounded-[12px] p-10 mt-3'>
              <p className='font-display-regular text-lite-black text-[0.75rem] text-center'>Looks like you don't have any favorite referral sources</p>
              <div className='flex items-center justify-center mt-2'>
                <button className='bg-[#F7943C] rounded-[12px] px-6 py-2 text-white font-display-regular text-base'>Favorite Now</button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <DashFooter />
        </div>
      </div>
    </div>
  )
}

export default Referral


