import React from 'react';
import drLogo from '../assets/img/DRkpeyes logo.svg';
// import splash from '../assets/img/Mask Group 2@2x.png';
// import splashN from '../assets/img/DRKPEyes-portrait.png';
import tagline from '../assets/img/tagline.svg';
import { useNavigate } from 'react-router-dom';

const Splash = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     const moveToDashboard = () => {
    //         setTimeout(function(){
    //             navigate('/dashboard');
    //         }, 3000);
    //     }
    //     moveToDashboard();
    // },[navigate]);

    return (
        <div className='bg-splash bg-cover h-screen overflow-scroll' onClick={() => navigate("/dashboard")}>
            <div className='absolute w-full bg-white top-16 h-48 flex flex-col justify-center items-center gap-5'>
                <img src={drLogo} alt='logo' />
                <img src={tagline} alt='tagline' />
            </div>
            <div className='absolute w-full bottom-4 font-display-regular flex justify-center text-lite-black'>
                v1.0
            </div>
        </div>
    )
}

export default Splash
