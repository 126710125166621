import React from 'react'

const Grid = (props) => {
  return (
      <div >
          <div className={props.classname}>

              <div className='flex justify-between'>
                  <div className={props.icolor}>
                      <input type="image" img src={props.icon} alt="image" className='h-5' />
                  </div>
                  <div className='rounded h-8 w-2 flex justify-center items-center my-2'>
                      <input type="image" img src={props.rightI} alt="image" className='h-3' />
                  </div>
              </div>

              <div className='text-white text-[0.875rem] font-display-regular'>{props.title}</div>
              <div className={props.subcolor}>{props.subTitle}</div>
          </div>
      </div>
  )
}

export default Grid