import React from 'react';
import back from "../../../assets/img/npBookingWindowAssets/backArrow.svg";
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const PayerMix = () => {
  const navigate = useNavigate();
  const data = [
    { name: 'Group A', value: 34.4 },
    { name: 'Group B', value: 10.8 },
    { name: 'Group C', value: 13.9 },
    { name: 'Group D', value: 25 },
    { name: 'Group E', value: 7.4 },
    { name: 'Group F', value: 8.5 },
  ];

  const COLORS = ['#34B5FE', '#18B854', '#F7943C', '#FF9300', '#FE0000', '#8570B2'];

  return (
    <div>
      <div className='bg-[#1B453E] w-full top-0 z-20'>
        <div className="flex justify-between px-4 pb-4 pt-14">
          <div className="cursor-pointer" onClick={() => navigate(-1)}><img src={back} alt="back" /></div>
          <div className="text-[#FFFFFF] text-base font-display-medium">Payer Mix</div>
          <div></div>
        </div>

        <div className="bg-white h-auto rounded-t-[26px] px-4">
          <div className='flex justify-center pt-6 pb-2'>
            <p className='text-lite-black font-display-medium text-base'>Transaction Post Date Payments</p>
          </div>
          <div className='h-64'>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  label
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className='pt-5'>
            <div className='flex justify-between px-6'>
              <div className='flex gap-2'>
                <div className='bg-[#34B5FE] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>Medicare</p>
              </div>
              <div className='flex gap-2'>
                <div className='bg-[#FF9300] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>UHC</p>
              </div>
              <div className='flex gap-2'>
                <div className='bg-[#F7943C] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>BCBS</p>
              </div>
              <div className='flex gap-2'>
                <div className='bg-[#18B854] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>Humana</p>
              </div>
            </div>
            <div className='flex justify-between px-28 py-4'>
              <div className='flex gap-2'>
                <div className='bg-[#FE0000] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>Others</p>
              </div>
              <div className='flex gap-2'>
                <div className='bg-[#8570B2] h-5 w-[7px] rounded-lg'>
                </div>
                <p className='font-display-medium text-lite-black text-[0.75rem]'>Wellcare</p>
              </div>
            </div>
          </div>

          <div className='p-2'>
            <div className='flex justify-center p-4 font-display-medium text-[0.75rem] text-[#1B453E]'>
              Payment Per Patient Visits
            </div>
          </div>
        </div>

        <div className='flex justify-end bg-white'>
          <div className='flex gap-[2.1rem] px-4 font-display-regular text-[0.8rem] w-[75%]'>
            <div className=''>Payment</div>
            <div>All</div>
            <div>EP</div>
            <div>NP</div>
            <div>IVP</div>
          </div>
        </div>

        <div className='bg-white p-4 max-h-[20rem] overflow-y-scroll'>
          <table className='bg-[#F5F5F5]  rounded-lg w-full top-[22rem]'>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>Medicare</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>34.4</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$380</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$63</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$138</td>
              <td className='p-2'>$551</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>BCBS</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>10.8%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$234</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$63</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$123</td>
              <td className='p-2'>$351</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>Humana</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>8.5%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
            <tr className='border-b border-[#C3C3C6]'>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>UHC</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>13.9%</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$279</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$64</td>
              <td className='border-r border-dashed border-[#C3C3C6] p-2'>$126</td>
              <td className='p-2'>$398</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  )
}

export default PayerMix
